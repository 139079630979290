<template>
  <el-container id="the_button" v-loading="dataLoading"
                element-loading-text="数据加载中"
                element-loading-background="rgba(255, 255, 255, 0.5)">
    <div class="the_button" :class="needFixed?'fixed':''">
      <el-button type="primary" @click="modifyData" size="small" :loading="saveLoading" v-if="isModify">
        修改报文
      </el-button>
      <el-button type="warning" @click="ReissueData" size="small" :loading="saveLoading" v-if="isReissue">
        重发报文
      </el-button>
      <el-button type="primary" @click="save2Wait()" size="small" :loading="saveLoading"
                 v-if="!this.baseForm.completionstatus||[0,1].includes(this.baseForm.completionstatus)||checkPer(['shManifest:agent_update'])">
        保存到待发送
      </el-button>
      <el-button type="primary" plain @click="save2Draft()" size="small" :loading="saveLoading"
                 v-if="!this.baseForm.completionstatus||[0,1].includes(this.baseForm.completionstatus)">
        保存到草稿箱
      </el-button>
      <el-button plain size="small" @click="close" :loading="saveLoading">关闭</el-button>
    </div>
    <div>
      <BaseFormModule :item="baseForm" ref="baseForm" @goodsType="goodsTypeChange"
                      :is-modify="isModify" :is-reissue="isReissue"></BaseFormModule>
      <ShipFormModule :item="baseForm" ref="shipForm"
                      :is-modify="isModify" :is-reissue="isReissue"
                      @checkShipAgentInfo="checkShipAgent"></ShipFormModule>
      <SFTFormModule ref="consignerForm" :item="consignerForm" :type-val="{title:'发货人',code:0}"
                     :countryList="countryList" @syncRSN="getSFTContactDetail"></SFTFormModule>
      <SFTFormModule ref="consigneeForm" :item="consigneeForm" :type-val="{title:'收货人',code:1}"
                     @syncNotice="syncNotice"
                     :countryList="countryList"></SFTFormModule>
      <SFTFormModule ref="informersForm" :item="informersForm" :type-val="{title:'通知人',code:2}"
                     :countryList="countryList"></SFTFormModule>
      <div class="the_title">货物明细</div>
      <MainfestGoodsTable ref="ManifestGoodsTable" :item="goodsList" :select-goods-type="selectGoodsType"
                          :is-modify="isModify" :is-reissue="isReissue"
                          @itemsDeleted="refreshGoodsList"></MainfestGoodsTable>
    </div>
    <CheckBillNosDialog ref="CheckBillNosDialog"></CheckBillNosDialog>
    <ShipAgentCheckDialog ref="shipAgentCheckDialog" @select="selectShipAgent" @sure="onSave(1)"></ShipAgentCheckDialog>
    <VesselsVoyageCheckDialog ref="vesselsVoyageCheckDialog" @sure="onSave(1)"
                              @changeVoyage="changeVoyage"></VesselsVoyageCheckDialog>
  </el-container>
</template>

<script>
import {
  getAllCountryCode,
  saveHead,
  getDetailById,
  saveAndResend, saveAndReissue, checkVesselsVoyageInfo, applyAgentUpdate, applyAgentReissue, checkShipAgentInfo,
} from "@/api/shanghaiManifest";
import DialogVue from "@/components/common/Dialog.vue";
import CheckBillNosDialog from "@/views/ShanghaiManifest/components/CheckBillNosDialog.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import MainfestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";
import {goodsType} from "@/data/manifestInformation";
import VesselsVoyageCheckDialog from "@/views/ShanghaiManifest/components/VesselsVoyageCheckDialog.vue";
import ShipAgentCheckDialog from "@/views/ShanghaiManifest/components/ShipAgentCheckDialog.vue";

export default {
  props: {
    item: {//列表传入的item
      type: Object,
      default: () => {
      }
    },
    index: {
      type: String,
      default: "0", //default 默认值，父组件不传值就显示默认
    },
    list: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    ShipAgentCheckDialog,
    VesselsVoyageCheckDialog,
    ShipFormModule,
    BaseFormModule,
    MainfestGoodsTable,
    SFTFormModule,
    DialogVue,
    CheckBillNosDialog,
  },
  computed: {
    needFixed() {
      if (this.scrollTop > 0) {
        return this.scrollTop >= this.offsetTop - 70;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      detail: {
        head: {},
        contacts: [],
        goods: []
      },
      baseForm: {},
      //发货人信息
      consignerForm: {},
      //收货人
      consigneeForm: {},
      //通知人
      informersForm: {},
      //货物明细
      goodsList: [],
      //港口列表
      portsList: [],//港口原数据
      portOptions: [],//下拉框的港口数据
      countryList: [],
      selectGoodsType: goodsType[0].value,//选择的货物类型
      showCheckBillNosResultDialog: false,
      saveLoading: false,
      dataLoading: false,
      offsetTop: 0,
      scrollTop: 0,
      isModify: false,//如果舱单已经发送，则只能修改部分数据
      isReissue: false,//如果舱单已经删除，可以修改除提单号外的其他数据，进行重发
    };
  },
  methods: {
    init() {
      this.offsetTop = document.getElementById("the_button").getBoundingClientRect().top;
      window.addEventListener('scroll', this.handleScroll)
      this.getCountryList();
      const params = this.$route.params
      if (params && params.id) {
        this.getDetail(params.id)
      } else {
        // create form
      }
    },
    getDetail(id) {
      this.dataLoading = true;
      getDetailById({id: id})
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.head;
            if (this.baseForm) {
              if (this.baseForm.completionstatus === 2 && this.baseForm.shipAgentBillStatus === 1) {
                this.isModify = true;
              } else if (this.baseForm.completionstatus === -2 || this.baseForm.completionstatus === -3) {
                this.isReissue = true;
              }
            }
            if (this.baseForm) {
              this.goodsTypeChange(this.baseForm.goodtype);
            }
            this.consignerForm = this.detail.contacts.filter(x => x.type === 0)[0];
            this.consigneeForm = this.detail.contacts.filter(x => x.type === 1)[0];
            this.informersForm = this.detail.contacts.filter(x => x.type === 2)[0];
            this.goodsList = this.detail.goods
            // console.log(response);
          }).finally(() => {
        this.dataLoading = false;
      })
    },
    //status，0：草稿箱，1：待发送，2：已发送
    save2Draft() {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.onSave(0)
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })

    },
    save2Wait(status = 1) {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
          this.$refs.shipForm.validate(),
          this.$refs.consignerForm.validate(),
          this.$refs.consigneeForm.validate(),
          this.$refs.informersForm.validate(),
          this.$refs.ManifestGoodsTable.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.checkVesselsAndVoyageInfo(status);
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    changeVoyage(entity) {
      this.$refs.shipForm.changeVoyage(entity);
    },
    checkShipAgent(status = 0) {
      if (!this.$refs.shipForm.shipForm.vesselname || !this.$refs.shipForm.shipForm.voyageno || !this.$refs.baseForm.form.scompany) {
        this.$message.error("请输入船名，航次和船公司")
        return false;
      }
      const param = {
        vesselName: this.$refs.shipForm.shipForm.vesselname,
        voyageNo: this.$refs.shipForm.shipForm.voyageno,
        carrierName: this.$refs.baseForm.form.scompany,
      }

      checkShipAgentInfo(param).then((res) => {
        if (res.data.status) {
          if (status !== 0 && res.data.data && res.data.data.length === 1 && res.data.data[0].shipAgencyValue === this.$refs.shipForm.shipForm.webtype) {
            this.onSave(status);
          } else {
            this.$refs.shipAgentCheckDialog.init(res.data.data, status === 1);
          }
        }
      }).finally(() => {
      })
    },
    //修改报文
    modifyData() {
      this.save2Wait(2);
    },
    //重发报文
    ReissueData() {
      this.save2Wait(3);
    },
    //校验分提单号是否符合主题单号
    checkBillNos() {
      const billNo = this.$refs.baseForm.form.billno;
      const goods = this.$refs.ManifestGoodsTable.form.goodsList;
      //查找是否有和主提单号一样的分提单号
      let same_goods = goods.filter((item) => item.billno === billNo);
      //查找是否有不属于主提单号的分提单号
      let error_goods = goods.filter((item) => !item.billno.includes(billNo));
      if (same_goods.length === 0) {
        this.$message.error("缺少与主提单号一致的分提单号，请检查！");
        return false;
      }
      if (error_goods.length >= 1) {
        this.$refs.CheckBillNosDialog.init(error_goods);
        return false;
      } else {
        return true;
      }
    },
    /**
     * 查询船名航次是否存在
     * @returns {boolean}
     */
    checkVesselsAndVoyageInfo(status = 1) {
      if (!this.$refs.shipForm.shipForm.vesselname || !this.$refs.shipForm.shipForm.voyageno) {
        this.$message.error("请输入船名和航次")
        return false;
      }
      let param = {
        vessels: this.$refs.shipForm.shipForm.vesselname,
        voyage: this.$refs.shipForm.shipForm.voyageno,
      }
      this.checkVesselsVoyageInfoLoading = true;

      let curr_ships = null;
      checkVesselsVoyageInfo(param).then((res) => {
        if (res.data.status) {
          curr_ships = res.data.data;
        }
      }).finally(() => {
        this.checkVesselsVoyageInfoLoading = false;
        if (curr_ships && curr_ships.length === 1 && curr_ships[0].vesselsName === param.vessels && curr_ships[0].voyage === param.voyage) {
          this.onSave(status)
        } else {
          this.$refs.vesselsVoyageCheckDialog.init(param, curr_ships);
        }
      })
    },
    onSave(status = 0) {
      this.saveLoading = true;
      this.baseForm = {...this.$refs.shipForm.shipForm, ...this.$refs.baseForm.form}
      this.baseForm.completionstatus = status === 3 ? 2 : status;
      //如果是企业身份需要传企业ID和名称
      this.baseForm.companyid = this.$store.getters.cid;
      this.baseForm.companyname = this.$store.getters.loginId;
      this.baseForm.totalpackno = this.$refs.ManifestGoodsTable.AllPackage;
      this.baseForm.totalvolume = this.$refs.ManifestGoodsTable.AllVolume;
      this.baseForm.totalweight = this.$refs.ManifestGoodsTable.AllWeight;
      this.detail = {
        head: this.baseForm,
        contacts: [this.$refs.consigneeForm.form, this.$refs.consignerForm.form, this.$refs.informersForm.form],
        goods: this.$refs.ManifestGoodsTable.form.goodsList
      }

      const request = status === 3 ? applyAgentReissue : status === 2 ? applyAgentUpdate : saveHead;

      request(this.detail).then((response) => {
        if (response.data.status) {
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: "保存失败！",
          });
        }
      }).finally(() =>
          this.saveLoading = false);
    },
    close() {
      this.$router.back();
    },
    //获取国家代码列表
    getCountryList() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            value: x.code + '-' + x.name,
            label: x.code + '-' + x.name
          }
        });
      });
    },
    refreshGoodsList(newList) {
      this.goodsList = newList
    },
    syncNotice(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.$refs.informersForm.syncByRevice(data)
    },
    getSFTContactDetail(data) {
      this.$refs.informersForm.getContactDetail(data.noticeId);
      this.$refs.consignerForm.getContactDetail(data.sendId);
      this.$refs.consigneeForm.getContactDetail(data.reviceId);

    },
    goodsTypeChange(type) {
      this.selectGoodsType = type;
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('scroll', this.scrollTop);
      // console.log('topOffset', this.offsetTop);
    },
    selectShipAgent(item) {
      this.$refs.baseForm.form.scompany = item.shipCompanyValue;
      this.$refs.shipForm.shipForm.webtype = item.shipAgencyValue;
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
#the_button {
  display: flex;
  flex-direction: column;
}

.the_button {
  display: flex;
  justify-content: right;
  background: white;
  padding: 1rem;
  box-shadow: 7px 2px 10px 0 var(--GRAY-dcdcdc);
}

.dialog-footer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-icon-arrow-down, .el-icon-arrow-up {
  cursor: pointer;
}

.fixed {
  position: fixed;
  top: 70px;
  right: 0;
  width: calc(90% - 20px);
  z-index: 10;
}

</style>
