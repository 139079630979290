<template>
  <div>
    <div class="the_title" @click="basicalInformation = !basicalInformation">基本信息
      <i :class="basicalInformation?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>
    <div v-show="basicalInformation">
      <el-form :inline="true" :model="form" ref="baseForm" class="demo-form-inline" label-position="right"
               label-width="150px" :rules="baseRules">

        <el-form-item label="主提单号：" prop="billno">
          <el-input
              :disabled="isLook||isModify||isReissue"
              :size="size"
              v-enterNextInput
              v-model="form.billno"
              @blur="form.billno =$event.target.value.trim().toUpperCase()"
              @input="inputFilter($event,'billno')"
              clearable
              placeholder="只能是大写字母或数字">
          </el-input>
          <!--                <div-->
          <!--                    style="width:600px;height: 20px;font-size: 10px;line-height: 20px;color: blue;position: absolute;">-->
          <!--                  {{ handleShipBlnoReamrk(this.form.scompany) }}-->
          <!--                </div>-->
        </el-form-item>
        <el-form-item label="船公司：" prop="scompany">
          <el-select
              :disabled="isLook||isModify||isReissue"
              :size="size"
              ref="scompany"
              v-enterNextInput
              v-model="form.scompany"
              placeholder="请选择船公司"
              filterable
              clearable>
            <el-option
                v-for="item in shipsCompany"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <div style="width:600px;height: 20px;font-size: 10px;line-height: 20px;color: blue;position: absolute;">
            {{ handleShipRemark(this.form.scompany) }}
          </div>
        </el-form-item>
        <el-form-item label="货物类型：" prop="goodtype">
          <el-select
              :disabled="isLook"
              :size="size"
              ref="goodtype"
              v-enterNextInput
              v-model="form.goodtype"
              @change="goodsTypeChange"
              placeholder="请选择货物类型"
              clearable>
            <el-option
                v-for="item in goodsType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="运输条款：" prop="transportClause">-->
        <!--          <el-select-->
        <!--              :size="size"-->
        <!--              disabled-->
        <!--              ref="transportClause"-->
        <!--              v-enterNextInput-->
        <!--              v-model="form.transportClause"-->
        <!--              placeholder="请选择"-->
        <!--              clearable>-->
        <!--            <el-option-->
        <!--                v-for="item in transportClause"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="付款方式：" prop="payment">-->
        <!--          <el-select-->
        <!--              :size="size"-->
        <!--              ref="payment"-->
        <!--              v-enterNextInput-->
        <!--              v-model="form.payment"-->
        <!--              @change="needClearPayarea"-->
        <!--              placeholder="请选择"-->
        <!--              clearable>-->
        <!--            <el-option-->
        <!--                v-for="item in payWay"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="提单类型：" prop="billtype">-->
        <!--          <el-select-->
        <!--              :size="size"-->
        <!--              ref="billtype"-->
        <!--              v-enterNextInput-->
        <!--              v-model="form.billtype"-->
        <!--              placeholder="请选择"-->
        <!--              clearable>-->
        <!--            <el-option-->
        <!--                v-for="item in billType"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="提单份数：" prop="bills">-->
        <!--          <el-input-->
        <!--              :size="size"-->
        <!--              ref="bills"-->
        <!--              @input="billsVisible"-->
        <!--              v-enterNextInput-->
        <!--              :min="1"-->
        <!--              v-model="form.bills"-->
        <!--              placeholder="只能是数字"-->
        <!--              clearable></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="签发地：" prop="signSpace">-->
        <!--          <el-select-->
        <!--              :size="size"-->
        <!--              disabled-->
        <!--              ref="signSpace"-->
        <!--              v-model="form.signSpace"-->
        <!--              filterable-->
        <!--              @blur="blur"-->
        <!--              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"-->
        <!--              :filter-method="filterPorts"-->
        <!--              v-enterNextInput-->
        <!--              placeholder="请输入签发地"-->
        <!--              clearable>-->
        <!--            <el-option-->
        <!--                v-for="item in portOptions.slice(0,rangeNumber)"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item label="付款地点：" prop="payarea" v-if="form.payment==='E-第三地付款'">
          <el-select
              :size="size"
              ref="payarea"
              v-model="form.payarea"
              :disabled="form.payment !== 'E-第三地付款'||isLook"
              filterable
              remote
              :remote-method="searchPorts"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              v-enterNextInput
              placeholder="请输入付款地点关键字"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <!--                    <span v-html="-->
              <!--                       htmlValue(item.port +'|' +item.portname,'payarea',)-->
              <!--                     "></span>-->
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="委托信息：" prop="cargoDescription">-->
        <!--          <el-input-->
        <!--              :disabled="isLook"-->
        <!--              :size="size"-->
        <!--              v-enterNextInput-->
        <!--              v-model="form.cargoDescription"-->
        <!--              placeholder="请输入客户备注"-->
        <!--              clearable-->
        <!--              show-word-limit-->
        <!--              maxlength="30">-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->

      </el-form>
    </div>
  </div>
</template>
<script>
import {
  billType,
  goodsType,
  payWay, restaurants,
  transportClause,
} from "@/data/manifestInformation";
import {setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {getAllSelectData, getAllShipCompanyData} from "@/api/shanghaiManifest";

export default {
  name: "BaseFormModule",
  props: {
    size: {
      type: String,
      default: "medium"
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    isLook: {//查看，不能编辑
      type: Boolean,
      default: false,
    },
    isModify: {//已发送的舱单进行修改
      type: Boolean,
      default: false,
    },
    isReissue: {//已删除的舱单进行重发
      type: Boolean,
      default: false,
    }
  },
  watch: {
    item(newVal) {
      if (newVal && newVal.id) {
        for (let key in this.form) {
          this.form[key] = newVal[key];
        }
      }
    },
  },
  computed: {
    handleShipRemark() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        return fiItem[0]?.remark || ''
      }
    }
    ,
  }
  ,
  data() {
    const validateBillNo = (rule, value, callback) => {
      if (!value || value.length < 6 || value.length > 30) {
        callback(new Error('提单号不符合,长度在6-30字符'));
      } else if (!(/(^[A-Z0-9]+)$/.test(value))) {
        callback(new Error('提单号只能是大写字母和数字'));
      } else {
        callback();
      }
    };

    return {
      portsList: [],
      basicalInformation: true, //基本信息
      baseRules: {
        billno: [
          {
            required: true,
            message: "请输入主提单号",
            trigger: "blur",
          },
          {validator: validateBillNo, trigger: 'blur'},
        ],
        scompany: [
          {
            required: true,
            message: "请选择船公司",
            trigger: "change",
          },
        ],
        transportClause: [
          {
            required: true,
            message: "请选择运输条款",
            trigger: "change",
          },
        ],
        goodtype: [
          {
            required: true,
            message: "请选择货物类型",
            trigger: "change",
          },
        ],
        payment: [
          {
            required: true,
            message: "请选择付款方式",
            trigger: "change",
          },
        ],
        billtype: [
          {
            required: true,
            message: "请选择提单类型",
            trigger: "change",
          },
        ],
        bills: [
          {
            required: true,
            message: "请输入提单份数",
            trigger: "blur",
          },
        ],
        signSpace: [
          {
            required: true,
            message: "请选择签发地",
            trigger: "change",
          },
        ],
        payarea: [
          {
            required: true,
            message: "请选择付款地点",
            trigger: "change",
          },
        ],
      },
      goodsType,
      payWay,
      billType,
      restaurants,
      transportClause,
      shipsCompany: [],
      form: {
        id: "",
        uuid: "",
        createuser:"",
        //基本信息
        billno: "", //主提单号
        remark: "", //备注
        scompany: "", //船公司
        goodtype: "S-普通货物", //货物类型
        payment: "P-预付", //付款方式
        billtype: "ORI-正本提单", //提单类型
        bills: 3, //提单份数
        payarea: "", //付款地点
        signSpace: "SHANGHAI|CNSHA", //签发地
        transportClause: "CY-CY", //运输条款
        cargoDescription: "", //货物描述
      },
      rangeNumber: 10,
      portOptions: [],
    }
  },
  methods: {
    searchShipCompany(keyword = '') {
      const data = {keyWord: keyword.trim()}
      getAllShipCompanyData(data).then(({data}) => {
        this.shipsCompany = data.data.map(x => {
          return {
            id: x.id,
            label: x.codeName,
            value: x.code + '—' + x.simpleName,
            remark: x.remark,
          }
        });
      });
    },
    inputFilter(value, key) {
      if (value) {
        this.form[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.form[key] = setRightForrmatPlus(value);
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    billsVisible(value) {
      let isVisble = /^(0+)|[^\d]+/g.test(value);
      if (isVisble || !value) {
        this.form.bills = "";
      } else {
        this.form.bills = Number(value);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.baseForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请检查基本信息必填项')
          }
        })
      });
    },
    //过滤港口数据
    filterPorts(query) {
      if (query) {
        this.portOptions = this.portsList.filter(x => {
          return x.label.includes(query.toUpperCase())
        })
      } else {
        this.portOptions = this.portsList
      }
    },
    //搜索港口
    searchPorts(port) {
      if (port.length < 2) {
        return
      }
      const data = {port: port}
      getAllSelectData(data).then(({data}) => {
        this.portsList = data.data.map(x => {
          return {
            id: x.id,
            label: x.portname + '|' + x.port,
            value: x.portname + '|' + x.port
          }
        });
        this.portOptions = this.portsList;
      });
    },
    needClearPayarea(newVal) {
      if (newVal !== 'E-第三地付款') {
        this.form.payarea = "";
      }
    },
    goodsTypeChange(val) {
      console.log('goodsType==',val)
      this.$emit('goodsType', val)
    }
  },
  mounted() {
    this.searchShipCompany();
  },
//自定义指令
  directives: {
    'el-select-loadmore':
        (el, binding) => {
          // 获取element-ui定义好的scroll盒子
          const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
          if (SELECTWRAP_DOM) {
            SELECTWRAP_DOM.addEventListener("scroll", function () {
              /**
               * scrollHeight 获取元素内容高度(只读)
               * scrollTop 获取或者设置元素的偏移值,
               *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
               * clientHeight 读取元素的可见高度(只读)
               * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
               * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
               */
              const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
              if (condition) binding.value();
            });
          }
        },
  }
}
</script>


<style scoped lang="stylus">
.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-form-item {
  //margin-bottom: 1rem;
}
/deep/ .el-input.is-disabled .el-input__inner{
  color:var(--BLACK-333)!important;
}
</style>
