<template>
  <div>
    <el-dialog
        title="船司船代检验结果"
        :visible.sync="dialogVisible"
        width="40%"
        append-to-body
        center
    >
      <el-table :data="data_list" style="width: 100%" stripe border
                max-height="500"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            v-for="item in tableHeaders"
            :prop="item.prop"
            :label="item.label"
            :width="item.width?item.width:'auto'"
            :key="item.prop"
        ></el-table-column>
        <slot name="todo" >
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <div v-if="data_list&&data_list[0].shipAgencyValue">
                <span class="update_span" @click="operator('select',scope.row)">选择</span>
              </div>
            </template>
          </el-table-column>
        </slot>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">
                    取 消
                </el-button>
              <el-button type="primary" @click="operator('sure')" v-if="showBtn">确认无误</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {eleDelete} from "@/api/shanghaiManifest";

export default {
  data() {
    return {
      tableHeaders: [
        {
          prop: "vesselName",
          label: "船名",
          width: 180,
        },
        {
          prop: "voyage",
          label: "航次",
          width: 100,
        },
        {
          prop: "shipCompany",
          label: "船公司",
          width: 180,
        },
        {
          prop: "shipAgency",
          label: "船代",
          width: 180,
        },
        // {
        //   prop: "loadPort",
        //   label: "靠港码头",
        // },
        // {
        //   prop: "maxShipRoute",
        //   label: "大航线",
        // },
        // {
        //   prop: "minShipRoute",
        //   label: "小航线",
        // },
      ],
      dialogVisible: false,
      showBtn: false,
      data_list: [],
    };
  },
  methods: {
    init(list,showSureBtn=false) {
      this.showBtn = showSureBtn;
      this.dialogVisible = true;
      this.data_list = list;
    },
    operator(type, item) {//edit,delete,copy
      this.$emit(type, item);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  cursor: pointer;
  margin-right: 1.5rem;
}
</style>
