<template>
  <div class='notice'>
    <img class="notice_icon" :src="importantMsg"/>
    <div class='notice_inner' v-html="htmlContent" v-if="!noticeAutoPlay"></div>
    <el-carousel v-else height="50px" direction="vertical" :autoplay="noticeAutoPlay" class="notice_inner"
                 indicator-position="none">
      <el-carousel-item v-for="item in htmlContents" :key="item" class="notice_item">
        <div v-html="item"></div>
      </el-carousel-item>
    </el-carousel>
    <el-button type="text" @click="noticeAutoPlay=!noticeAutoPlay">{{ noticeAutoPlay ? '展开' : '收起' }}<i
        :class="noticeAutoPlay?'el-icon-arrow-down':'el-icon-arrow-up'"></i></el-button>
  </div>
</template>
<script>
import importantMsg from "@/assets/img/shanghaiManifest/important_msg.png"
import {getNoticeById} from "@/api/sysMsg";

export default {
  name: "NoticeTopBar",
  props: {
    noticeId: {
      type: Number,
      default: '',
    }
  },
  data() {
    return {
      noticeAutoPlay: false,
      importantMsg: importantMsg,
      htmlContent: '',
      htmlContents: [],
    }
  },
  mounted() {
    this.getNoticeMsg();
  },
  methods: {
    getNoticeMsg() {
      if (this.noticeId) {
        getNoticeById({id: this.noticeId}).then((res) => {
          if (res.data.code === 200 && res.data.data.status === 'T') {
            this.htmlContent = res.data.data.contents;
            this.htmlContents = this.htmlContent.split('</p>');
            this.htmlContents = this.htmlContents.filter(x => x.trim().length > 0);
            this.htmlContents = this.htmlContents.map(item => {
              return item + '</p>'
            })
          }
        })
      }
    },
  },
}
</script>
<style scoped lang="stylus">

.notice {
  background: var(--YELLOW-FFF7E8);
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  align-items: center;
  border:2px solid var(--YELLOW-EDB518);

  .notice_icon {
    width: 56px;
    height: 56px;
  }

  .notice_inner {
    margin: 0 1rem;
    width: 100%;

    /deep/ p {
      margin: 0;
      line-height: 1.8;
    }

    .notice_item {
      display: flex;
      align-items: center;
    }
  }
}
</style>
