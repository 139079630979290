<template>
  <div>
    <el-dialog title="舱单详情" :visible.sync="centerDialogVisible" width="70%" center>
      <div class="the_content">
        <BaseFormModule :item="baseForm" ref="baseForm" :is-look="true"></BaseFormModule>
        <ShipFormModule :item="baseForm" ref="shipForm" :is-look="true"></ShipFormModule>
        <SFTFormModule ref="consignerForm" :item="consignerForm" :type-val="{title:'发货人',code:0}" :is-look="true"
        ></SFTFormModule>
        <SFTFormModule ref="consigneeForm" :item="consigneeForm" :type-val="{title:'收货人',code:1}" :is-look="true"
        ></SFTFormModule>
        <SFTFormModule ref="informersForm" :item="informersForm" :type-val="{title:'通知人',code:2}" :is-look="true"
        ></SFTFormModule>
        <div class="the_title">货物明细</div>
        <MainfestGoodsTable ref="ManifestGoodsTable" :item="goodsList" :is-look="true"></MainfestGoodsTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import MainfestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";
import {
  getAllCountryCode,
  saveHead,
  getDetailById,
} from "@/api/shanghaiManifest";

export default {
  components: {ShipFormModule, SFTFormModule, BaseFormModule, MainfestGoodsTable},
  data() {
    return {
      centerDialogVisible: false,
      detail: {
        head: {},
        contacts: [],
        goods: []
      },
      baseForm: {},
      //发货人信息
      consignerForm: {},
      //收货人
      consigneeForm: {},
      //通知人
      informersForm: {},
      //货物明细
      goodsList: [],
    }
  },
  methods: {
    init(item) {
      this.centerDialogVisible = true;
      console.log('item detail', item);
      this.getDetail(item.id)
    },
    getDetail(id) {
      getDetailById({id: id})
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.head;
            this.consignerForm = this.detail.contacts.filter(x => x.type === 0)[0];
            this.consigneeForm = this.detail.contacts.filter(x => x.type === 1)[0];
            this.informersForm = this.detail.contacts.filter(x => x.type === 2)[0];
            this.goodsList = this.detail.goods
            console.log(response);
          })
    },
  }
}
</script>

<style lang="stylus" scoped>
.the_content {
  height: 60vh;
  clear: both;
  overflow-y: scroll;

  .the_title {
    user-select: none;
    cursor: pointer;
    padding: 0.6rem 0;
    font-size: 16px;
    text-align: center;
    background: var(--GRAY-dcdcdc);
    margin-bottom: 1rem;
  }
}
</style>
