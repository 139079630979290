<template>
  <div>
    <el-dialog
        title="检验提单号"
        :visible.sync="dialogVisible"
        width="40%"
        append-to-body
        center
    >

      <p class="tip_span">
        以下分提单号与主提单号不符，请检查：</p>
      <el-table :data="data_list" style="width: 100%" stripe border
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            v-for="item in tableHeaders"
            :prop="item.prop"
            :label="item.label"
            :width="item.width?item.width:'auto'"
            :key="item.prop"
        ></el-table-column>
        <!--        <slot name="todo">-->
        <!--          <el-table-column fixed="right" label="操作">-->
        <!--            <template slot-scope="scope">-->
        <!--              <div>-->
        <!--                <span class="update_span" @click="operator('select',scope.row)">选择</span>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </slot>-->
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">
                    取 消
                </el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  watch: {
    data_list(newVal) {
      return newVal;
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          prop: "billno",
          label: "分提单号",
        },
        {
          prop: "ctnno",
          label: "箱号",
        },
        {
          prop: "sealno",
          label: "封号",
        },
      ],
      dialogVisible: false,
      data_list: [],
    };
  },
  methods: {
    init(data) {
      this.data_list=[];
      for (var i = 0; i < data.length; i++) {
        this.data_list.push(data[i]);
      }
      this.dialogVisible = true;
    },
    operator(type, item) {//edit,delete,copy
      this.$emit(type, item);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  cursor: pointer;
  margin-right: 1.5rem;
}

.tip_span {
  color: var(--RED-F21137);
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
}
</style>
