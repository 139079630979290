<template>
  <div>
    <el-dialog
        title="以下舱单删除失败"
        :visible.sync="deleteDialogVisible"
        width="40%"
        append-to-body
        center
    >
      <el-table :data="deleteData" style="width: 100%" stripe border
                max-height="500"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            prop="billno"
            label="提单号"
            width="200"
        ></el-table-column>
        <el-table-column
            prop="vesselname"
            label="船名"
        ></el-table-column>
        <el-table-column prop="voyageno" label="航次" width="160"></el-table-column>
        <el-table-column prop="fcwStatusStr" label="原因" width="160"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">
                    关 闭
                </el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {applyAgentDelete, applyCounterDelete, eleDelete} from "@/api/shanghaiManifest";
import {agentDelete} from "@/api/shanghaiManifest";

export default {
  data() {
    return {
      deleteLoading: false,
      deleteDialogVisible: false,
      deleteData: [],
    };
  },
  methods: {
    init(list) {
      this.deleteDialogVisible = true;
      this.deleteData = list;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
