// 获取设置的公告和通知
import { request } from "@/utils/request.js";
import {axiosEnum, axiosEnum_JDWX} from "./enum";

const FEST_URL = axiosEnum_JDWX[process.env.VUE_APP_API_ENV];

// 获取公告
export function getNoticeById(param) {
    return request("/notice/info", "get", param, {
        baseURL: FEST_URL,
    });
}

