<template>
  <div>
    <div class="the_title" @click="shipInformation = !shipInformation">
      船舶信息
      <i :class="shipInformation?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>

    <div v-show="shipInformation">
      <el-form :inline="true" :model="shipForm" class="demo-form-inline" label-position="right"
               label-width="80px" :rules="baseRules" ref="shipForm">
        <el-form-item label="船名：" prop="vesselname">
          <el-autocomplete
              :fetch-suggestions="shipSearch"
              @select="handleShipSelect"
              :trigger-on-focus="false"
              :disabled="isLook||isModify||isReissue"
              :size="size"
              class="the_base"
              v-enterNextInput
              @input="inputFilter2($event,'vesselname')"
              v-model="shipForm.vesselname"
              @blur="shipForm.vesselname =$event.target.value.trim().toUpperCase()"
              placeholder="请输入船名,只能是大写字符和数字"
              clearable></el-autocomplete>
        </el-form-item>
        <el-form-item label="航次：" prop="voyageno">
          <el-input
              :disabled="isLook||isModify||isReissue"
              :size="size"
              class="inline-input the_base"
              ref="voyageno"
              @input="inputFilter2($event,'voyageno')"
              v-enterNextInput
              v-model.trim="shipForm.voyageno"
              @blur="shipForm.voyageno =$event.target.value.trim().toUpperCase()"
              placeholder="请输入航次"
              clearable>
            <el-button slot="append" v-if="!(isLook||isModify||isReissue)" :size="size" type="primary"
                       @click="checkVesselsVoyageInfo"
                       :loading="checkVesselsVoyageInfoLoading">检验航次
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="船代：" prop="webtype">
          <el-select
              :disabled="isLook||isModify||isReissue"
              :size="size"
              class="the_base"
              ref="webtype"
              v-enterNextInput
              v-model="shipForm.webtype"
              filterable
              placeholder="请选择"
              clearable>
            <el-option
                v-for="item in shipAgent"
                :key="item.value"
                :label="item.label"
                :value="item.label + '|' + item.value"
                @change="getLabel(item.value)"></el-option>
          </el-select>
          <el-button v-if="!(isLook||isModify||isReissue)" :size="size" type="primary" @click="checkShipAgentInfo"
                     :loading="checkShipAgentLoading">查看船司船代
          </el-button>
        </el-form-item>
        <el-form-item label="发货地：" prop="originname">
          <el-select
              :disabled="isLook"
              :size="size"
              class="inline-input the_base"
              ref="originname"
              v-enterNextInput
              v-model="shipForm.originname"
              filterable
              remote
              :remote-method="searchPorts"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              placeholder="请输入发货地关键字"
              title="国内内支线填写时请注意！例如南京收货经上海出，发货地为南京"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px">{{ item.country }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="装货港：" prop="loadPort">
          <el-select
              :size="size"
              disabled
              class="inline-input the_base"
              ref="loadPort"
              v-enterNextInput
              v-model="shipForm.loadPort"
              filterable
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              remote
              :remote-method="searchPorts"
              placeholder="请输入发货地关键字"
              title="国内内支线填写时请注意！例如南京收货经上海出，发货地为南京"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px">{{ item.country }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卸货港：" prop="unloadport">
          <el-select
              :disabled="isLook"
              :size="size"
              class="inline-input the_base"
              ref="unloadport"
              v-enterNextInput
              v-model="shipForm.unloadport"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              filterable
              remote
              :remote-method="searchPorts"
              placeholder="请输入卸货港关键字"
              clearable
              @change="syncDestPort"
          >
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px">{{ item.country }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="目的港：" prop="destportname">
          <el-select
              :disabled="isLook"
              :size="size"
              class="inline-input the_base"
              ref="destportname"
              v-enterNextInput
              v-model="shipForm.destportname"
              filterable
              remote
              :remote-method="searchPorts"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              placeholder="请输入目的港关键字"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px">{{ item.country }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <VesselsVoyageCheckDialog ref="vesselsVoyageCheckDialog" @changeVoyage="changeVoyage"></VesselsVoyageCheckDialog>
  </div>
</template>
<script>
import {setRightForrmat, setRightForrmatPlus, setRightForrmatSpace2} from "@/utils/validate";
import {
  checkShipAgentInfo,
  checkVesselsVoyageInfo,
  getAllSelectData,
  getAllShipCompanyData,
  getShipAgentList
} from "@/api/shanghaiManifest";
import ShipAgentCheckDialog from "@/views/ShanghaiManifest/components/ShipAgentCheckDialog.vue";
import VesselsVoyageCheckDialog from "@/views/ShanghaiManifest/components/VesselsVoyageCheckDialog.vue";
import {searchShip} from "@/api/boxQuery";

export default {
  name: "ShipFormModule",
  props: {
    size: {
      type: String,
      default: "medium"
    },
    isLook: {
      type: Boolean,
      default: false,
    },
    isModify: {//已发送的舱单进行修改
      type: Boolean,
      default: false,
    },
    isReissue: {//已删除的舱单进行重发
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    ShipAgentCheckDialog,
    VesselsVoyageCheckDialog
  },
  watch: {
    item(newVal) {
      if (newVal && newVal.id) {
        for (let key in this.shipForm) {
          this.shipForm[key] = newVal[key];
        }
      }
    }
  },
  computed: {},
  data() {
    const validateVessel = (rule, value, callback) => {
      if (!(/(^[A-Z0-9.\- ]+)$/.test(value))) {
        callback(new Error('船名只能是大写字母 数字 . -'));
      } else {
        callback();
      }
    };
    const validateVoyageNo = (rule, value, callback) => {
      if (!(/(^[A-Z0-9 .]+)$/.test(value))) {
        callback(new Error('航次只能只能是大写字母 数字 .'));
      } else if(value.includes('v.')|| value.includes('V.')) {
        callback(new Error('航次不能有 V.'));
      } else {
        callback();
      }
    };
    const validatePort = (rule, value, callback) => {
      if (!(/(^[A-Z \-'.,:;"]+[|][A-Z]{5})$/.test(value))) {
        callback(new Error('港口格式： 港口全称 | 五字码'));
      } else {
        callback();
      }
    };
    return {
      checkShipAgentLoading: false,
      checkVesselsVoyageInfoLoading: false,
      baseRules: {
        vesselname: [
          {
            required: true,
            message: "请输入船名",
            trigger: "blur",
          },
          {validator: validateVessel, trigger: 'blur'},

        ],
        voyageno: [
          {
            required: true,
            message: "输入航次",
            trigger: "blur",
          },
          {validator: validateVoyageNo, trigger: 'blur'},
        ],
        webtype: [
          {
            required: true,
            message: "请选择船代",
            trigger: "change",
          },
        ],
        originname: [
          {
            required: true,
            message: "请输入发货地",
            trigger: "blur",
          },
          {validator: validatePort, trigger: 'blur'},
        ],
        loadPort: [
          {
            required: true,
            message: "请输入装货港",
            trigger: "blur",
          },
          {validator: validatePort, trigger: 'blur'},
        ],
        unloadport: [
          {
            required: true,
            message: "请输入卸货港",
            trigger: "blur",
          },
          {validator: validatePort, trigger: 'blur'},
        ],
        destportname: [
          {
            required: true,
            message: "请输入目的港",
            trigger: "blur",
          },
          {validator: validatePort, trigger: 'blur'},
        ],
      },
      shipAgent: [],//船代
      shipForm: {
        //船舶信息
        vesselname: "", //船名
        voyageno: "", //航次
        webtype: "", //船代
        originname: "SHANGHAI|CNSHA", //发货地
        loadPort: "SHANGHAI|CNSHA", //装货港
        unloadport: "", //卸货港
        destportname: "", //目的港
      },
      rangeNumber: 10,
      portsList: [],
      portOptions: [],
      shipInformation: true, //船舶信息
      searchLikeShipList: [], //模糊搜索的船名航次
    }
  },
  methods: {
    searchPorts(port) {
      if (port.trim().length < 2) {
        return
      }
      const data = {port: port.trim()}
      getAllSelectData(data).then(({data}) => {
        this.portsList = data.data.map(x => {
          return {
            id: x.id,
            label: x.portname + '|' + x.port,
            value: x.portname + '|' + x.port,
            country: x.country,
          }
        });
        this.portOptions = this.portsList;
      });
    },
    getShipAgentList() {
      getShipAgentList().then(({data}) => {
        this.shipAgent = data.data.map(x => {
          return {
            id: x.id,
            label: x.name,
            value: x.code,
          }
        });
      })
    },
    inputFilter(value, key) {
      if (value) {
        this.shipForm[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.shipForm[key] = setRightForrmatPlus(value);
      }
      if (key === 'vesselname') {
        this.checkInputVesselsName(this.shipForm[key]);
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.shipForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请检查船舶信息必填项')
          }
        })
      });
    },
    syncDestPort(newVal) {
      this.shipForm.destportname = newVal;
    },
    //过滤港口数据
    filterPorts(query) {
      if (query) {
        this.portOptions = this.portsList.filter(x => {
          return x.label.includes(query.toUpperCase())
        })
      } else {
        this.portOptions = this.portsList
      }
    },
    /**
     * 查询船名航次是否存在
     * @returns {boolean}
     */
    checkVesselsVoyageInfo() {
      if (!this.shipForm.vesselname || !this.shipForm.voyageno) {
        this.$message.error("请输入船名和航次")
        return false;
      }
      const param = {
        vessels: this.shipForm.vesselname,
        voyage: this.shipForm.voyageno,
      }
      this.checkVesselsVoyageInfoLoading = true;

      checkVesselsVoyageInfo(param).then((res) => {
        if (res.data.status) {
          this.$refs.vesselsVoyageCheckDialog.init(param,res.data.data);
        }
      }).finally(() => {
        this.checkVesselsVoyageInfoLoading = false;
      })
    },
    changeVoyage(entity){
      this.shipForm.vesselname=entity.vesselsName;
      this.shipForm.voyageno=entity.voyage;
    },
    //检验船名航次船司船代是否匹配
    checkShipAgentInfo() {
      this.$emit('checkShipAgentInfo');
    },
    //船名航次模糊搜索
    checkInputVesselsName(val) {
      let newVal = val
      if (newVal) {
        newVal = setRightForrmatSpace2(val)
        this.$emit('change', newVal)
      } else {
        this.$emit('change', '')
      }
      if (newVal.length >= 3) {
        searchShip({vesselsName: newVal})
            .then(({data}) => {
              this.searchLikeShipList = data.data.map((item) => ({
                value: `${item.s}/${item.v}`,
              }));
            })
            .catch(() => {
              this.searchLikeShipList = [];
            });
      } else if (newVal.length < 3) {
        this.searchLikeShipList = [];
      }
    },
    //船名航次搜索框带输入建议
    shipSearch(queryString, cb) {
      const restaurants = this.searchLikeShipList || [];
      const results = queryString
          ? restaurants.filter(this.shipFilter(queryString))
          : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    //搜索框选择了输入建议
    handleShipSelect(item) {
      const selectShip = item.value.split("/");
      this.shipForm.vesselname = selectShip[0];
      this.shipForm.voyageno = selectShip[1];
    },
    shipFilter(queryString) {
      return (restaurant) => {
        return (
            restaurant.value.indexOf(queryString.toUpperCase()) != -1
        );
      };
    },
  },
  mounted() {
    this.getShipAgentList();
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
}
</script>


<style scoped lang="stylus">
.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-form-item {
  //margin-bottom: 1rem;
}

/deep/ .el-input-group__append {
  color: white;
  background: var(--BLUE-409eff);
  border: unset;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: var(--BLACK-333) !important;
}
</style>
